.Project-List {
  margin-bottom: 20px;
}

.filter-groups {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 1rem 0;
}

.filter-groups .Select {
  min-width: 150px;
}

.New-Project-Form {
  border: 3px solid #000;
  border-radius: 4px;
  padding: 20px 20px 20px;
}

.Project {
  text-align: left;
}

.Project-award,
.Project-vote {
  float: right;
}
.Project-vote {
  font-style: italic;
}
.Project-award {
  margin-left: 1em;
}

.Project-summary {
  border: 0;
  background: none;
  padding: 0;
  margin-bottom: 20px;
}

.Project-summary a {
  text-decoration: underline;
}

.Project-award-list {
  list-style: none;
  padding-left: 0;
}

.Project-award-list li {
  font-size: 1.2em;
}

.Project-award-list li .glyphicon {
  font-size: 0.8em;
}

.Project-member-list {
  list-style: none;
  padding-left: 0;
}

.Project-meta .avatar,
.Project-member-list .avatar {
  float: left;
  margin-right: 5px;
  width: 18px;
  height: 18px;
  border-radius: 2px;
}

.Project-member-list-condensed {
  font-size: 0.8em;
}

.Project-member-list-condensed .Project-member {
  display: inline-block;
  margin-right: 10px;
}
.Project-member-list-condensed .Project-member .avatar {
  margin-right: 5px;
  width: 16px;
  height: 16px;
}

.Project-group-badge {
  background: black;
  color: white;
  border-radius: 6px;
  width: fit-content;
  padding: 2px 6px;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 0.5rem;
  text-transform: uppercase;
}

/* Viendetta badge */
.Project-group-badge.-O1sPFU0PwMmtCUJjQ78 {
  background: #ee871b;
}

/* Canadalada badge */
.Project-group-badge.-O1sPEcGDurB79pjG0Gf {
  background: #f644a6;
}

/* San Attle badge */
.Project-group-badge.-O1sPGaicIe7NPWOYgml {
  background: #7bae01;
}

.Project-meta {
  font-size: 0.8em;
  margin-bottom: 20px;
}

.Project-media {
  margin-bottom: 20px;
}

.Project-media .Project-media-item {
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 2px;
  border: 1px solid #000;
}
.Project-media .Project-media-item a {
  display: block;
}
.Project-media .Project-media-item video {
  display: block;
  max-width: 490px;
  max-height: 300px;
}
.Project-media .Project-media-item img {
  max-width: 150px;
  max-height: 150px;
}
.Project-media .Project-media-item em {
  display: block;
  padding: 5px;
  width: 100%;
  height: 100%;
  border: 1px solid #eee;
}

.Project-idea-summary {
  font-size: 0.9em;
  margin-right: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Project-idea-claim {
  float: right;
}

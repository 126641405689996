@import url('https://fonts.googleapis.com/css?family=Rubik:400,500');

body {
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  transition: 0.1s linear color;
  color: #111;
}
a:hover {
  color: #000;
}

strong,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

dl,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
p,
table,
form,
blockquote {
  margin: 0 0 20px 0;
}

ul,
ol {
  padding-left: 20px;
}

li {
  margin-bottom: 5px;
}

pre {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

dl dt {
  font-weight: 500;
}
dl dd {
  margin-bottom: 10px;
}

.btn {
  border-width: 2px;
}

.btn-default {
  background: #fff;
  color: #666;
  border-color: #999;
}
.btn-default:hover .btn-default:active {
  border-color: #999;
  background-color: #999;
  color: #fff;
}

.btn-primary {
  background: #fff;
  color: #7b6be6;
  border-color: #7b6be6;
}
.btn-primary:hover,
.btn-primary:active {
  border-color: #7b6be6;
  background-color: #7b6be6;
  color: #fff;
}

.btn-danger {
  background: #fff;
  color: #e03e2f;
  border-color: #e03e2f;
}
.btn-danger:hover .btn-danger:active {
  border-color: #e03e2f;
  background-color: #e03e2f;
  color: #fff;
}

.btn-set .btn {
  margin-left: 5px;
}
.btn-set .btn:first-child {
  margin-left: 0;
}

.media-loading {
  width: 50x;
  height: 50px;
  display: inline-block;
}

.form-group .checkbox,
.form-group .radio {
  margin: 0;
}

.alert {
  font-size: 0.9em;
  border: 0;
}

.alert blockquote {
  padding: 0;
  margin-bottom: 0;
  font-size: inherit;
  border: 0;
  font-style: italic;
}

.alert blockquote header {
  display: block;
  font-style: normal;
  margin-bottom: 20px;
}

.Year-List {
  margin-bottom: 20px;
}

.Year {
  text-align: left;
  list-style: none;
  margin-bottom: 20px;
  opacity: 0.6;
}

.Year:hover {
  opacity: 1;
}

.Year-name {
  margin-bottom: 5px;
  font-size: 1.2em;
  font-weight: bold;
}

.Year-name a:hover {
  text-decoration: none;
}

.Year-name a .glyphicon {
  font-size: 0.8em;
  margin-left: 5px;
  font-weight: normal;
}

.Year-member-list {
  display: block;
  list-style: none;
  padding-left: 0;
}

.Year-member-list li {
  display: inline-block;
  margin-left: -3px;
  border-left: 1px solid #fff;
}

.Year-member-list li:first-child {
  margin: 0;
  border-left: 0;
}

.Year-member .avatar {
  width: 18px;
  height: 18px;
  border-radius: 2px;
}

.Year-award-list {
  display: block;
  list-style: none;
  padding-left: 0;
  font-size: 0.8em;
}

.Year-award-list a {
  font-weight: normal;
  display: inline;
  text-decoration: underline;
}

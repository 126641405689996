.App {
  max-width: 800px;
  margin: 0 auto;
}

.App-header {
  padding: 20px 0;
  margin: 0;
  border-bottom: 3px solid #000;
  margin-bottom: 20px;
  overflow: hidden;
  margin-left: 20px;
  margin-right: 20px;
}

.App-title {
  font-size: 2em;
  line-height: 60px;
  float: left;
  margin-bottom: 0;
}

.App-title a,
.App-title a:hover {
  text-decoration: none;
  color: inherit;
}

.App-title small {
  font-size: inherit;
  color: #ccc;
  font-weight: bold;
}

.App-auth {
  float: right;
  height: 40px;
  display: block;
  overflow: hidden;
  width: 200px;
  margin-top: 10px;
  font-size: 0.8em;
  text-align: right;
}

@media (max-width: 500px) {
  .App-auth p {
    display: none;
  }
  .App-auth {
    width: 36px;
  }
}

.App-auth p {
  margin-bottom: 0;
}

.App-avatar {
  float: right;
  margin-left: 5px;
  width: 36px;
  height: 36px;
  position: relative;
}

.App-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.App-avatar button {
  border: 0;
  padding: 0;
  display: inline-block;
  background: inherit;
  cursor: pointer;
}

.App-avatar button:hover:before {
  font-family: 'Glyphicons Halflings';
  content: '\e017';
  position: absolute;
  left: 0;
  top: 0;
  height: 36px;
  width: 36px;
  font-size: 24px;
  text-align: center;
  line-height: 36px;
}
.App-avatar button:hover > img {
  opacity: 0.2;
}

.Modal {
  text-align: center;
  border: 10px solid #000;
  margin: 20px auto;
  border-radius: 4px;
  padding: 20px 40px 20px;
}

.App-main {
  padding-left: 20px;
  padding-right: 20px;
}

.App-main h3 {
  text-transform: uppercase;
  letter-spacing: -1px;
  color: #333;
  border-bottom: 3px solid #333;
  padding: 0 0 5px 0;
  font-size: 16px;
}

.App-footer {
  margin: 20px 0;
  background: #f9f9f9;
  padding: 20px;
  text-align: center;
  font-size: 0.8em;
}

.App-footer a {
  text-decoration: underline;
  font-weight: normal;
  margin: 0 10px;
}
